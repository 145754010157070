
  import Vue from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import InfiniteLoading, {StateChanger} from 'vue-infinite-loading';
  import {CHAT_TYPES, CompetitionLastChatMessage, LastChatMessage, removeLastMessage} from "@/services/chat";
  import {ROUTES} from "@/router/routes";
  import pageMixin from "@/mixins/page";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import PwaReportUserModal from "@/components/modals/PwaReportUserModal.vue";
  import {MODAL_IDS} from "@/utils/modal";
  import {OpenAppLink} from "@/utils/app-launcher";
  import {EVENTS} from "@/utils/events";

  const MESSAGES_LOAD_GROUP = 20;

  const TABS = {
    USERS: 0,
    COMPETITIONS: 1,
  };

  export default Vue.extend({
    name: "Messages",
    mixins: [pageMixin],
    data() {
      return {
        searchUsername: '',
        lastMessageSelected: null,
        chatData: null,
        defaultUserChatLastMessage: null as LastChatMessage,
        currentLastMessageLimit: MESSAGES_LOAD_GROUP,
        selectedTab: TABS.USERS,
        gameLink: null as OpenAppLink,
      }
    },
    components: {
      PwaIcon,
      InfiniteLoading,
      PwaChatContainer: () => import("@/components/chat/PwaChatContainer.vue"),
      PwaPrice: () => import("@/components/common/PwaPrice.vue"),
      PwaWebAdLargeMobileBanner: () => import('@/components/ads/PwaWebAdLargeMobileBanner.vue'),
    },
    mounted(): void {
      if (this.isDesktop) {
        if (this.defaultUsername) {
          this.selectDefaultUsername();
        } else if (this.numMessages > 0) {
          this.selectDefaultUser();
        }

        this.$root.$on(EVENTS.USER_BLOCK_MANAGEMENT_UPDATED, this.reloadLastMessageSelected);
      }
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.USER_BLOCK_MANAGEMENT_UPDATED, this.reloadLastMessageSelected);
    },
    computed: {
      lastChatMessageList(): Array<LastChatMessage|CompetitionLastChatMessage> {
        const allLastMessagesList = this.currentLastMessagesList || [];
        let lastMessages: Array<LastChatMessage> = [];
        if (this.searchUsername) {
          const searchValue = this.searchUsername.toLowerCase();
          lastMessages = allLastMessagesList.filter(({userName}) => userName ? userName.toLowerCase().includes(searchValue) : '');
        } else {
          lastMessages = allLastMessagesList.slice(0, this.currentLastMessageLimit);
        }

        return lastMessages;
      },
      allLastMessagesList(): Array<LastChatMessage> | null {
        return this.$store.state.user.chat.lastMessages;
      },
      allCompetitionsLastMessagesList(): Array<CompetitionLastChatMessage> | null {
        return this.$store.state.user.chat.competitionsLastMessages;
      },
      currentLastMessagesList(): Array<CompetitionLastChatMessage|LastChatMessage> | null {
        return this.isUsersTab ? this.allLastMessagesList : this.allCompetitionsLastMessagesList;
      },
      numMessages(): number {
        const allLastMessagesList = this.currentLastMessagesList || [];
        return allLastMessagesList.length;
      },
      defaultUsername(): string {
        return this.$route.hash.replace('#', '');
      },
      chatConnected(): boolean {
        return this.$store.state.user.chat.connected;
      },
      breadcrumb(): BreadcrumbItemType[] {
        return [{labelId: BreadcrumbItems.messages.labelId}];
      },
      isUsersTab(): boolean {
        return this.selectedTab === TABS.USERS;
      },
    },
    methods: {
      selectDefaultUsername() {
        this.onLastMessageSelected({userName: this.defaultUsername, fromUserId: null, userId: null, messageId: null, message: null} as LastChatMessage, true);
      },
      reloadLastMessageSelected() {
        this.onLastMessageSelected(this.lastMessageSelected, false, true);
      },
      async onLastMessageSelected(lastMessage: LastChatMessage|CompetitionLastChatMessage, defaultUser = false, force = false) {
        const username = lastMessage.userName;
        const {competitionId, matchId} = (lastMessage as CompetitionLastChatMessage);
        if (this.isDesktop) {
          if (this.lastMessageSelected !== lastMessage || force) {
            try {
              this.showLoader(true);
              if (this.isUsersTab) {
                const params = {username, addDescription: 1};
                const {data} = await this.$http.user.getChatData(CHAT_TYPES.USERS, params);
                this.chatData = data.data;
                this.gameLink = null;
              } else {
                const params: Record<string, string|number> = {paginate: 1, matchId};
                const {data} = await this.$http.page.getCompetitionPage(competitionId, params);
                const competition = data.data.competition || {};
                this.chatData = data.data.chat_data || null;
                this.gameLink = {
                  iosUrl: competition.ios_url ? competition.ios_url : null,
                  androidUrl: competition.android_url ? competition.android_url : null,
                  deepLink: competition.deeplink ? competition.deeplink : null,
                  packageName: competition.package_name ? competition.package_name : null,
                };
              }

              if (defaultUser) {
                const allLastMessagesList: Array<LastChatMessage> = this.allLastMessagesList || [];
                const defaultUserChatLastMessage: LastChatMessage | null = allLastMessagesList.find(({userName}) => userName === username);
                const {to_user} = this.chatData;
                this.defaultUserChatLastMessage = defaultUserChatLastMessage
                  ? defaultUserChatLastMessage
                  : {
                    userAvatar: to_user.avatar,
                    isImage: false,
                    isRead: true,
                    userName: to_user.username,
                    userId: to_user.id,
                    message: '',
                    messageId: null,
                    time: null,
                    fromUserId: null,
                    isBlocked: to_user.blocked
                  };

                this.lastMessageSelected = this.defaultUserChatLastMessage;
              } else {
                this.lastMessageSelected = lastMessage;
              }

            } catch (e) {
              this.showToastError(this.$t('139'), this.$t('140'));
            } finally {
              this.showLoader(false);
            }
          }
        } else {
          if (this.isUsersTab) {
            this.routerPush(ROUTES.messagesUser.name, {username});
          } else {
            this.routerPush(ROUTES.competitionChat.name, {competitionId, matchId});
          }
        }
      },
      showBlockModal(lastChatMessage: LastChatMessage) {
        this.showModalById(MODAL_IDS.USER_BLOCK_MANAGEMENT, {username: lastChatMessage.userName});
      },
      showReportModal(reportUserId: number, reportUsername: string) {
        const message = this.$createElement(PwaReportUserModal, {
          props: {
            reportUserId,
            reportUsername,
          },
        });
        this.showModal({
          id: MODAL_IDS.MODAL_REPORT_USER,
          message,
          contentClass: {'hide-footer': true, 'is-desktop': this.isDesktop},
        });
      },
      showRemoveLastMessageModal(userId: number, defaultUser = false) {
        this.showModal({
          title: this.$t('271'),
          message: this.$t('273'),
          onOkClick: () => this.removeLastChatMessage(userId, defaultUser),
        });
      },
      async removeLastChatMessage(userId: number, defaultUser = false) {
        try {
          this.showLoader(true);
          await removeLastMessage(this.userId, userId);
          if (defaultUser) {
            this.defaultUserChatLastMessage = null;
            this.routerPush({name: ROUTES.messages.name, hash: ''});
          }
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      selectDefaultUser() {
        const firstMessage: LastChatMessage = this.lastChatMessageList[0];
        this.onLastMessageSelected(firstMessage);
      },
      loadMoreMessages($state: StateChanger) {
        this.currentLastMessageLimit = this.currentLastMessageLimit + MESSAGES_LOAD_GROUP;
        $state.loaded();
      },
      goToUserProfile(username: string) {
        this.routerPush(ROUTES.profile.name, {username});
      },
      goToCompetition(competitionId: number) {
        this.routerPush(ROUTES.competition.name, {competitionId});
      },
      getRefereeAvatar() {
        return this.getBrandImageSrc('referee-avatar.png');
      },
    },
    watch: {
      currentLastMessagesList() {
        if (this.isDesktop && this.numMessages > 0) {
          if (this.isUsersTab) {
            if (this.lastMessageSelected === null && !this.defaultUsername) {
              this.selectDefaultUser();
            }

            if (this.defaultUserChatLastMessage) {
              const allLastMessagesList: Array<LastChatMessage> = this.allLastMessagesList || [];
              const defaultUserChatLastMessage: LastChatMessage | null = allLastMessagesList.find(({userName}) => userName === this.defaultUserChatLastMessage.userName);
              if (defaultUserChatLastMessage) {
                this.defaultUserChatLastMessage = defaultUserChatLastMessage;
              }
            }
          } else {
            if (this.lastMessageSelected === null) {
              this.selectDefaultUser();
            }
          }
        }
      },
      defaultUsername() {
        if (this.isDesktop) {
          if (this.defaultUsername) {
            this.selectDefaultUsername();
          }
        }
      },
      selectedTab() {
        this.chatData = null;
        this.lastMessageSelected = null;
        this.searchUsername = '';
        this.currentLastMessageLimit = MESSAGES_LOAD_GROUP;
        if (this.numMessages > 0 && this.isDesktop) {
          this.selectDefaultUser();
        }
      },
    },
  });
