
  import Vue from "vue";
  import {MODAL_IDS} from "@/utils/modal";
  import {ROUTES} from "@/router/routes";

  export default Vue.extend({
    name: "pwa-report-user-modal",
    props: {
      reportUserId: {
        type: Number,
        required: true,
      },
      reportUsername: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        optionSelected: '',
        reporterUserValue: '',
        selectOptions: [
          {value: '', labelId: '651'},
          {value: '648', labelId: '648'},
          {value: '649', labelId: '649'},
          {value: 'other', labelId: '650'},
        ],
      };
    },
    methods: {
      async onReportClick() {
        if (this.optionSelected === 'other') {
          this.$router.push({name: ROUTES.support.name, params: {lang: this.routeLang}});
          this.onCancelClick();
        } else {
          const query = {
            from_user: this.userId,
            to_user: this.reportUserId,
            description: this.$t(this.optionSelected),
          };

          this.showLoader(true);
          try {
            await this.$http.user.postReporterUser(query);
            this.showLoader(false);
            this.showToastSuccess(this.$t('359'), this.$t('653'));
            this.onCancelClick();
          } catch (e) {
            this.showLoader(false);
            this.showToastError(this.$t('139'), this.$t('140'));
          }
        }
      },
      onCancelClick() {
        this.hideModalById(MODAL_IDS.MODAL_REPORT_USER);
      },
    }
  });
